<template>
  <NuxtImg
    v-if="image"
    :format="image.mimeType !== 'image/svg' ? 'webp' : ''"
    :src="getApiImageThumbnail(image)"
    :alt="getAlt(image)"
    :loading="loading"
    quality="80"
    :sizes="$attrs.sizes || 'xxl:100vw'"
    :style="{ 'object-position': getFocusPoint(image) }"
    v-bind="$attrs"
  />
</template>

<script setup lang="ts">
import { useRuntimeConfig } from 'nuxt/app';
import { type PropType } from 'vue';
import useApiImage from '@/composables/useApiImage';
import { type SuluMedia } from '@/types/Sulu';

defineProps({
  image: {
    type: Object as PropType<SuluMedia>,
    required: true,
  },
  loading: {
    type: String,
    default: 'lazy',
    validator: (value) => ['lazy', 'eager'].includes(value),
  },
});

const config = useRuntimeConfig();
const { getApiImageThumbnail, getAlt, getFocusPoint } = useApiImage(config.public.apiUrl);
</script>
